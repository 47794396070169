<template>
    <div>
        <v-container fluid>

            <Overlay :overlay="overlay"/>

            <CardStatistics :is-loading="isLoading" :statistic="statistic"/>

            <v-row>
                <v-col cols="12">
                    <v-card class="shadow rounded-lg">

                        <v-card-title>

                            <div class="d-flex align-center">
                                <v-text-field v-model="keyword"
                                              :style="{width : '250px'}"
                                              append-icon="mdi-magnify"
                                              clearable
                                              dense
                                              hide-details
                                              outlined placeholder="N°Téléphone..."
                                              single-line/>

                                <v-text-field v-model="code"
                                              :style="{width : '180px'}"
                                              append-icon="mdi-magnify"
                                              class="ml-2"
                                              clearable
                                              dense
                                              hide-details
                                              outlined placeholder="Code..."
                                              single-line/>
                            </div>

                            <v-spacer/>

                            <v-btn v-if="Object.keys(filter).length"
                                   class="grey lighten-5 mr-2"
                                   color="red"
                                   text @click="[filter = {},getConsents()]">
                                <v-icon left>mdi-filter-off</v-icon>
                                Filtre
                            </v-btn>

                            <v-btn class="mr-2"
                                   color="secondary"
                                   depressed
                                   @click="$refs.consentFormDialog.open()">
                                <v-icon left>mdi-plus</v-icon>
                                Consentement
                            </v-btn>

                            <v-btn color="secondary" text
                                   @click="$func.export('consents/export-excel')">
                                <v-icon left>mdi-download</v-icon>
                                Expoter
                            </v-btn>

                            <v-btn color="secondary" text
                                   @click="$refs.filterConsentsDialog.open()">
                                <v-icon left>mdi-filter</v-icon>
                                Filtre
                            </v-btn>

                            <v-btn color="secondary" icon @click="getConsents">
                                <v-icon>mdi-refresh</v-icon>
                            </v-btn>

                        </v-card-title>

                        <v-divider/>

                        <v-card-text>

                            <v-skeleton-loader v-if="isLoading" type="table"/>

                            <div v-else>
                                <div v-if="consents.length">
                                    <v-simple-table class="table-border rounded-lg">
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th>N°Téléphone</th>
                                                <th>Code</th>
                                                <th>Sms</th>
                                                <th class="text-center">Coût</th>
                                                <th class="text-no-wrap">Envoyé le</th>
                                                <th>Statut</th>
                                                <th class="text-no-wrap">Apprové le</th>
                                            </tr>
                                            </thead>
                                            <tbody>

                                            <tr v-for="(item,index) in consents" :key="index">

                                                <td class="text-no-wrap">
                                                    <v-icon color="primary" dense>mdi-phone-outline</v-icon>
                                                    {{ item.phone }}
                                                </td>

                                                <td class="text-no-wrap red--text font-weight-medium">
                                                    {{ item.code }}
                                                </td>

                                                <td>
                                                    <div :class="item.consent.lang === 'fr' ? '' : 'ar'"
                                                         :dir="item.consent.lang === 'fr' ? 'ltr' : 'rtl'"
                                                         class="py-2">
                                                        {{ item.sms }}
                                                    </div>
                                                </td>

                                                <td class="text-center">
                                                    <v-chip class="bg-primary-subtle font-weight-medium"
                                                            small
                                                            text-color="secondary">
                                                        {{ item.sms_cost | toCurrency }} DZD
                                                    </v-chip>
                                                </td>

                                                <td>
                                                    {{ item.sent_at ? $func.dateFormat(item.sent_at) : '-' }}
                                                </td>

                                                <td>
                                                    <div class="d-block text-no-wrap grey--text text--darken-1">
                                                        <v-icon :color="switchStatus(item.status)['color']" small>
                                                            {{ switchStatus(item.status)['icon'] }}
                                                        </v-icon>
                                                        {{ switchStatus(item.status)['value'] }}
                                                    </div>
                                                </td>

                                                <td>
                                                    {{
                                                    item.status === 'approved' && item.approved_at ? $func.dateFormat(item.approved_at) : '-'
                                                    }}
                                                </td>

                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <v-card-actions v-if="total > 10">

                                        <div>
                                            <v-select v-model="per_page"
                                                      :items="[10,20,50,100,200,300,400,500]"
                                                      :style="{width: '120px'}"
                                                      dense
                                                      hide-details
                                                      label="Ligne par page"
                                                      outlined></v-select>
                                        </div>

                                        <v-spacer/>
                                        <v-pagination v-if="total>10"
                                                      v-model="pagination.current"
                                                      :length="pagination.total"

                                                      total-visible="6"
                                                      @input="onPageChange"
                                        ></v-pagination>
                                    </v-card-actions>

                                </div>
                                <NoResult v-else/>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <ConsentFormDialog ref="consentFormDialog" :databases="databases" @refresh="getConsents"/>
            <FilterConsentsDialog ref="filterConsentsDialog"
                                  @search="[pagination.current = 1,filter = $event,getConsents()]"/>

        </v-container>
    </div>
</template>

<script>
import {HTTP} from "@/http-common";
import CardStatistics from "@/views/consents/components/CardStatistics.vue";
import FilterConsentsDialog from "@/views/consents/components/FilterConsentsDialog.vue";
import ConsentFormDialog from "@/views/consents/components/ConsentFormDialog.vue";

export default {
    components: {ConsentFormDialog, FilterConsentsDialog, CardStatistics},
    data() {
        return {
            consents: [],
            overlay: false,
            isLoading: false,

            code: null,
            keyword: null,
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {},

            statistic: {
                all: 0,
                approved: 0,
                not_approved: 0,
                error: 0,
                cost: 0,
            },
            databases: [],
        }
    },

    methods: {
        getConsents() {
            this.isLoading = true
            HTTP.get('/consents?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    code: this.code,
                    per_page: this.per_page,
                    filter: this.filter
                }
            }).then((res) => {
                this.isLoading = false
                this.consents = res.data.data.data
                this.statistic = res.data.statistic

                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getConsents();
        },
        getDatabase() {
            HTTP.get('/database').then((res) => {
                this.databases = res.data.data
            }).catch(err => {
                console.log(err)
            })
        },
        switchStatus(status) {
            switch (status) {
                case "sent":
                    return {
                        value: 'Envoyé',
                        icon: 'mdi-email-send',
                        color: 'info',
                    }
                case "approved":
                    return {
                        value: 'Approuvé',
                        icon: 'mdi-check-circle',
                        color: 'teal',
                    }
                case "error":
                    return {
                        value: 'Échoué',
                        icon: 'mdi-alert',
                        color: 'red',
                    }
                case "received":
                    return {
                        value: 'Reçu',
                        icon: 'mdi-cellphone-message',
                        color: 'success',
                    }
                default:
                    return {
                        value: 'En attente',
                        icon: 'mdi-timer-sand',
                        color: 'orange',
                    }
            }
        }
    },
    created() {
        this.getDatabase()
        this.getConsents()
    },
    watch: {
        keyword() {
            this.getConsents();
        },
        code() {
            this.getConsents();
        },
        per_page() {
            this.pagination.current = 1
            this.getConsents();
        },
    },
}
</script>

<style scoped>

</style>