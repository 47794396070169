<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card class="shadow rounded-lg">
          <v-card-text class="pa-0">

            <div class="d-flex justify-space-between align-center">


              <div class="d-flex align-center justify-space-between pa-4">

                <v-avatar class="primary mr-4" size="45">
                  <v-icon color="white" size="25">mdi-currency-usd</v-icon>
                </v-avatar>

                <div>
                  <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                  <span v-else
                        class="font-weight-medium d-block secondary--text fs-17">
                    {{ statistic.cost | toCurrency }}
                  </span>

                  <p class="mt-1 mb-0 text-no-wrap">
                    Coût total
                    <span class="fs-12 red--text d-block">DZD</span>
                  </p>
                </div>
              </div>

              <div class="d-flex align-center justify-space-between pa-4">

                <v-avatar class="bg-grad-primary mr-4" size="45">
                  <v-icon color="primary" size="25">mdi-file-document-outline</v-icon>
                </v-avatar>

                <div>
                  <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                  <span v-else
                        class="font-weight-medium d-block secondary--text fs-17">{{ statistic.all }}</span>

                  <p class="mt-1 mb-0 text-no-wrap">
                    Consentements
                    <span class="fs-12 red--text d-block">Total envoyés</span>
                  </p>
                </div>
              </div>


              <div class="d-flex align-center justify-space-between pa-4">

                <v-avatar class="bg-grad-primary mr-4" size="45">
                  <v-icon color="primary" size="25">mdi-check-circle-outline</v-icon>
                </v-avatar>

                <div>
                  <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                  <span v-else
                        class="font-weight-medium d-block secondary--text fs-17">{{ statistic.approved }}</span>

                  <p class="mt-1 mb-0">
                    Approuvés
                    <span class="fs-12 red--text d-block">Par Tél.</span>
                  </p>
                </div>
              </div>


              <div class="d-flex align-center justify-space-between pa-4">

                <v-avatar class="bg-grad-primary mr-4" size="45">
                  <v-icon color="primary" size="25">mdi-timer-sand</v-icon>
                </v-avatar>

                <div>
                  <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                  <span v-else
                        class="font-weight-medium d-block secondary--text fs-17">{{ statistic.not_approved }}</span>

                  <p class="mt-1 mb-0 text-no-wrap">
                    Non approuvés
                    <span class="fs-12 red--text d-block">Par Tél.</span>
                  </p>
                </div>
              </div>


              <div class="d-flex align-center justify-space-between pa-4">

                <v-avatar class="bg-grad-primary mr-4" size="45">
                  <v-icon color="primary" size="25">mdi-alert-octagon-outline</v-icon>
                </v-avatar>

                <div>
                  <v-skeleton-loader v-if="isLoading" type="text" width="50"/>
                  <span v-else
                        class="font-weight-medium d-block secondary--text fs-17">{{ statistic.error }}</span>

                  <p class="mt-1 mb-0 ">
                    Échecs
                    <span class="fs-12 red--text d-block">Par Tél.</span>
                  </p>
                </div>
              </div>

            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
    props: ['isLoading', 'statistic']
}
</script>

<style scoped>

</style>