<template>
  <div>

    <v-dialog v-model="dialog" persistent scrollable width="750">

      <v-card :disabled="isLoading">

        <v-card-title>
          Consentement
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <Progression ref="progression"
                     v-if="isLoading"
                     :is-loading="isLoading"
                     :message="loadingMessage"
                     class="pa-15"/>

        <v-card-text v-if="!isLoading" class="pa-10">

          <v-item-group v-model="database_ids" multiple>
            <v-row>
              <v-col v-for="(database,index) in databases" :key="index" class="d-flex flex-column"
                     cols="4">
                <v-item v-slot="{ active, toggle }" :value="database.id">
                  <v-card class="rounded-lg flex d-flex flex-column"
                          outlined
                          @click="toggle">

                    <v-card-title class="pb-1">
                      <v-icon v-if="active" color="success">mdi-check-circle</v-icon>
                      <v-icon v-else>mdi-circle</v-icon>
                    </v-card-title>

                    <v-card-text class="text-center pa-0 flex">

                      <div class="text-center">
                        <v-progress-circular :value="database.approved_percent"
                                             color="red"
                                             rotate="50"
                                             size="50"
                                             width="3"
                        >
                          <v-avatar class="" size="30">
                            <database-icon :style="{width:'20'}"/>
                          </v-avatar>
                        </v-progress-circular>
                      </div>
                      <p class="mb-1 mt-3 px-3 font-weight-medium">
                        {{ database.name }}
                      </p>
                    </v-card-text>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
          </v-item-group>

          <v-radio-group v-model="type" :error-messages="errors.type"
                         label="Sélectionnez un type (*)">

            <v-radio label="Tous les propriétaires" value="all"></v-radio>

            <v-radio label="Propriétaires n'ayant pas reçu de sms" value="not_received"></v-radio>

          </v-radio-group>


          <v-alert border="bottom" class="fs-14" dense prominent text type="info">
            <span class="d-block">
             <span class="font-weight-medium">Nom :</span>
              Il remplace le nom du propriétaire.
            </span>
            <span class="d-block">
              <span class="font-weight-medium">Url :</span>
             il remplace le lien d'activation.
            </span>
          </v-alert>


          <v-radio-group v-model="lang"
                         :error-messages="errors.lang"
                         label="Sélectionnez la langue des SMS (*)"
                         row>

            <v-radio label="Français" value="fr"></v-radio>

            <v-radio label="Arabe" value="ar"></v-radio>

          </v-radio-group>

          <v-textarea ref="textarea"
                      v-model.trim="sms"
                      :class="lang === 'ar' ? 'ar':''"
                      :dir="lang === 'ar' ? 'rtl' : 'ltr'"
                      :disabled="!lang"
                      :error-messages="errors.sms"
                      :label="lang === 'ar' ? 'الرسالة القصيرة (*)':'SMS (*)'"
                      :placeholder="lang === 'ar' ? 'اكتب الرسالة القصيرة هنا...':'Écrivez le SMS ici...'"
                      clearable
                      outlined
                      prepend-inner-icon="mdi-cellphone-message"
                      rows="5"
          ></v-textarea>

        </v-card-text>

        <v-divider v-if="!isLoading"/>

        <v-card-actions v-if="!isLoading" class="grey lighten-4">
          <v-spacer/>
          <v-btn :disabled="!database_ids.length || !sms || !type"
                 :loading="isLoading"
                 color="secondary"
                 depressed
                 @click="checkSmsCost">
            <v-icon left>mdi-email-send-outline</v-icon>
            Envoyer
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

    <ConfirmDialog ref="confirmDialog"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import DatabaseIcon from "@/components/svg-icons/DatabaseIcon.vue";
import Progression from "@/components/Progression.vue";

export default {
    props: ['databases'],
    components: {
        Progression,
        DatabaseIcon,
    },
    data() {
        return {
            dialog: false,
            isLoading: false,
            loadingMessage: '',
            errors: {},

            /**
             * form
             */
            lang: '',
            type: '',
            sms: '',
            database_ids: [],
            /***
             *
             */

            smsAr: "سلام {{Nom}} لتلقي الرسائل من كوكا كولا كجزء من برنامج الوفاء، يرجى تفعيل استقبال الرسائل القصيرة من خلال هذا الرابط {{Url}}",
            smsFr: "Salam {{Nom}} activez la réception des SMS Coca-Cola pour le programme de fidélité via : {{Url}}",
        }
    },
    watch: {
        /**
         *
         * @param val
         */
        lang(val) {
            if (val === 'ar') {
                this.sms = this.smsAr
            } else {
                this.sms = this.smsFr
            }
        }
    },
    methods: {
        open() {
            this.database_ids = []
            this.lang = 'fr'
            this.type = ''
            this.sms = this.smsFr
            this.errors = {}
            this.dialog = true
        },
        checkSmsCost() {

            this.isLoading = true

            this.$nextTick(() => {
                this.$refs.progression.reset()
                this.$refs.progression.startTimer()
            })
            this.loadingMessage = "Traitement en cours, veuillez patienter..."

            this.errors = {}
            HTTP({
                url: '/consents/check-sms-cost',
                method: 'POST',
                data: {
                    lang: this.lang,
                    type: this.type,
                    sms: this.sms,
                    database_ids: this.database_ids,
                },
            }).then((res) => {
                this.isLoading = false
                this.save(res.data.data)
            }).catch(err => {
                this.isLoading = false
                this.$refs.progression.reset()
                this.errors = err.response.data.errors
                console.log(err)
            })
        },

        async save(data) {

            let others = "<p>Total propriétaires  : <span class='font-weight-medium red--text'>" + data.count + "</span></p>"
                + "<p>Coût total  : <span class='font-weight-medium red--text'>" + data.cost + " DZD</span></p>"

            if (await this.$refs.confirmDialog.open('', 'Êtes-vous sûr de vouloir envoyer ces sms ?', {
                icon: 'mdi-email-send-outline',
                agreeText: 'Valider',
                color: 'warning',
                isDisabled: !data.count,
            }, others)) {

                this.isLoading = true

                this.$nextTick(() => {
                    this.$refs.progression.reset()
                    this.$refs.progression.startTimer()
                })
                this.loadingMessage = "Enregistrement en cours, veuillez patienter..."

                HTTP({
                    url: '/consents/store',
                    method: 'POST',
                    data: {
                        lang: this.lang,
                        type: this.type,
                        sms: this.sms,
                        database_ids: this.database_ids,
                    },
                }).then(() => {
                    this.dialog = false
                    this.$emit('refresh')
                    this.$successMessage = "Les sms sont actuellement envoyées à tous les propriétaires concernés"
                    this.isLoading = false
                }).catch(err => {
                    this.isLoading = false
                    this.$refs.progression.reset()
                    console.log(err)
                })
            }
        },
    },
}
</script>

<style scoped>

</style>