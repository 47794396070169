<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="400">

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">
          <v-radio-group v-model="filter.status" hide-details label="Statut" >
            <v-radio label="En attente" value="pending"></v-radio>
            <v-radio label="Envoyé" value="sent"></v-radio>
            <v-radio label="Reçu" value="received"></v-radio>
            <v-radio label="Approuvé" value="approved"></v-radio>
            <v-radio label="Échoué" value="error"></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn color="secondary"
                 depressed
                 @click="search">
            <v-icon left>mdi-magnify</v-icon>
            Recherche
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        return {
            isLoading: false,
            dialog: false,
            filter: {
               status: 'pending',
            },
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.filter = {
                status: 'pending',
            }
        },
        search() {
            this.dialog = false
            this.$emit('search', this.filter)
        },
    }
}
</script>

<style scoped>

</style>